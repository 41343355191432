// here we are overriding the blue and green variables that are imported below. This is to remove some of the
// 'bootstrappy' feeling of bootstrap

$blue: #166fe4;
$green: #36a420;

$border-radius: 0;
$paragraph-margin-bottom: 0;
$gray-600: rgb(245, 245, 245);
@import '~bootstrap/scss/bootstrap';

body {
  
  background: #ffffff;
}
// /* Styles for small screens */
// @media (max-width: 600px) {
//   .contain {
//     flex-direction: column;
//   }

//   .sidebar {
//     flex: 0 0 auto;
//     /* Add other styles for small screens */
//   }

//   .chat-area {
//     flex: 1;
//     /* Add other styles for small screens */
//   }
// }
.user-div:hover {
  background-color: rgb(169, 218, 171);
}

 .user-div {
   background-color: rgb(236, 184, 184);
 }

.messages-box {
height: -webkit-fill-available;
  overflow-y: scroll;
    background-color: rgb(248, 247, 242);

  scrollbar-width: none;

  // this is to remove the scrollbar

  &::-webkit-scrollbar {
    display: none;
  }
}

.user-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.message-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.info-text {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-bottom: 1rem;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* Adjust as needed */
}
.spinner {
  border: 4px solid #a2dd54;
  border-top: 4px solid #eb37c4;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
  // margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/**/
.rightbarFriend {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.rightbarProfileImgContainer {
  margin-right: 10px;
  position: relative;
}

.rightbarProfileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.rightbarOnline {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: -2px;
  right: 0;
  border: 2px solid white;
}

.rightbarUsername {
  font-weight: 500;
}