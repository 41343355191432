/* HomePage.css */

.home-page {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
}

header {
    background-color: #f2f2f2;
    padding: 10px;
}

nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
}

nav ul li {
    margin-right: 10px;
}

main {
    margin-top: 20px;
}

section {
    margin-bottom: 20px;
}

.about-section {
    background-color: #f9f9f9;
    padding: 20px;
}

.contact-section {
    background-color: #eaf3ff;
    padding: 20px;
}

h2 {
    color: #333;
}

.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.form-group {
    margin-bottom: 10px;
}

label {
    font-weight: bold;
}

input[type='text'],
input[type='email'],
textarea {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 100%;
    /* Adjust the width to 100% */
}

button[type='submit'] {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}