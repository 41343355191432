.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ae80eb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* App.css */

.app-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.phone-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-image img {
  max-width: 100%;
  max-height: 100%;
}

.register-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* App.css */

.container {
  display: flex;
  justify-content: space-between;
}

.left-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  /* background-color: #f1f1f1; */
}

.right-content {
  flex: 1;
  display: flex;
  align-items: center;
  float: left;
  background-color: #fff;
}

.register-form {
  /* Add your styles for the register form here */
  color: #fff;
}

/* TopBar.css */

.top-bar {
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  /* justify-content: flex-end; */
}

nav ul li {
  margin-left: 10px;
}

nav ul li:first-child {
  margin-left: 0;
}

/* HomePage.css */

/* Add your styles for the home page here */

/* Register.css */

/* Add your styles for the register page here */

/* Login.css */

/* Add your styles for the login page here */

/* ChatBubble.css */

/* Add your styles for the chat bubble component here */

/* ContactUs.css */

/* Add your styles for the contact us page here */